@@ -0,0 +1,294 @@
<template>
  <b-card>
    <b-container>
      <b-row class="mb-2">
          <b-col cols="12" style="display: flex; flex-direction: column; justify-items: center; align-items: center">
            <!-- <img :src="paperless" class="warranty-logo"/> -->
            <h2 class="mb-2 font-weight-bold text-center">Login with One Time Password (OTP)</h2>
          </b-col>
        </b-row>
    
    <validation-observer :slim="true" ref="OTPValidation">
      <div>
        <div class="section-data">
              <h5>Email Verification</h5>
              <p style="font-weight:bold; color:red;">
                Note: Please enter your email to receive an OTP for email validation 
              </p>
              <validation-provider
                v-if="inputState"
                #default="{ errors }"
                name="Email"
                rules="required|email"
                tag="div"
                class="form-line"
              >
                <label>Email</label>

                <div class="form-validation-input">
                  <b-col>
                  <b-row class="hide-on-desktop">
                    <b-col style="padding-left:0" cols="12">
                  <b-form-input placeholder="Enter Email" disabled v-model="emailForLogin" :state="errors.length > 0 ? false:null" ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                    </b-col>
                    <!-- <b-col cols="4"><b-button variant="primary" @click="sendOTP">Request OTP</b-button></b-col> -->
                  </b-row>
                  <b-row class="hide-on-mobile">
                    <b-col style="padding-left:0" cols="8">
                  <b-form-input placeholder="Enter Email" disabled v-model="emailForLogin" :state="errors.length > 0 ? false:null" ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                    </b-col>
                    <!-- <b-col cols="4"><b-button variant="primary" @click="sendOTP">Request OTP</b-button></b-col> -->
                  </b-row>
                  </b-col>
                </div>
              </validation-provider>
              <div><p></p></div>

              <!-- <div><p style="font-weight:bold">If you did not received the OTP in 30 seconds, please click <a href=# @click="resend"><u>Resend OTP</u></a></p></div> -->
              <div><p style="font-weight:bold">If you did not received the OTP in 30 seconds, please click <a href=# @click="sendOTP"><u>Resend OTP</u></a></p></div>

              <validation-provider
                tag="div"
                name="otp"
                class="form-line"
                rules="digits:4"
                #default="{ errors }"
              >
              <label>OTP</label>       
              
              <div class="form-validation-input">
                <b-col >
                <b-row class="hide-on-desktop">
                <b-col cols="12" style="padding-left:0">
                <b-form-input placeholder="Enter OTP" v-model="submit.otp" :state="errors.length > 0 ? false:null"></b-form-input>
                
                <small class="text-danger">{{ errors[0] }}</small>
                </b-col>
                <b-col cols="4" style="padding-left:0"><b-button class="mt-1" variant="primary" @click="verify">Submit OTP</b-button></b-col>
                </b-row>
                <b-row class="hide-on-mobile">
                <b-col cols="8" style="padding-left:0">
                <b-form-input placeholder="Enter OTP" v-model="submit.otp" :state="errors.length > 0 ? false:null"></b-form-input>
                
                <small class="text-danger">{{ errors[0] }}</small>
                </b-col>
                <b-col cols="4"><b-button variant="primary" @click="verify">Submit OTP</b-button></b-col>
                </b-row>
                </b-col>
              </div>             
              </validation-provider>

              <validation-provider
                tag="div"
                class="form-line"
              >
              <!-- <div style="width:74%; text-left:left;">
                
                <b-button style="margin: 1% 2% 2% 2%" @click="resend">resend</b-button>
                <b-button style="margin: 1% 0% 2% 2%" @click="verify">verify</b-button>
              </div> -->
              </validation-provider>
            </div>
            <!-- <b-btn class="float-right" :disabled="pass" variant="primary" @click="toListWarranty">next</b-btn> -->
      </div>
    </validation-observer>
    <div id="some_div"></div>
    </b-container>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations';
import axios from '@/axios';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      timerLeft : 30,
      // paperless: require('@/assets/images/drcc/daikin-paperless.png'),
      pass:true,
      form: {
        email: '',
      },
      submit:{otp:''},
      inputState : false
    }
  },
  methods: {
    ...mapActions({
      addLog : 'log/addLog'
    }),
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    sendOTP() {
      let email = 'tang@trillium-tech.com'
      this.$store.dispatch('auth/requestOtp',{
        email : this.emailForLogin
      }).then(res => {
        this.$bvToast.toast(`request resend OTP has been sent to ${this.emailForLogin}`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }).catch(e => {
        console.log({e})
        this.$bvToast.toast(`${e.response.data.errors[0].message}`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      })
    },
    resend() {
      axios.post('sales-and-purchase/drcc/resend-otp', this.form).then((response) => {
        this.$bvToast.toast(`Request for resend OTP has been sent to ${this.form.email}`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }).catch((e) => {
        this.$bvToast.toast(`Failed to resend an OTP, ${e.response.data.errors[0].message}`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      })
    },
    verify() {
      console.log('this.state',this.emailForLogin)
      let toSend = {email : this.emailForLogin, otp:this.submit.otp}
      this.$store.dispatch('auth/verifyOtp', toSend).then(res => {
        this.$store.dispatch('auth/login', {
          email : this.emailForLogin,
          password : this.passwordForLogin
        }).then((userData) => {
              var permission = JSON.parse(this.$session.get('permission'));
              console.log('loginDataPermission',permission)

              let userAccess = permission[Object.keys(permission)[0]].filter(el => {
                return el.view;
              }).map(el => {
                return `${el.name.replace(/\s+/g, '-').toLowerCase()}`;
              })

              this.$bvToast.toast('Login Successfully', {
                title: 'Success',
                variant: 'success',
                solid: true,
              })
              this.addLog({
                email:userData.email,
                message:"Logged In via OTP",
                name:userData.name,
              }).then((res)=>{
                setTimeout(() => {
                  this.$router.push('/');
                },3000)
              })
              
        }).catch(err => {
            this.$bvToast.toast('Invalid Credentials', {
            title: 'Failed',
            variant: 'danger',
            solid: true,
          })
          console.log(err)
        })
      }).catch((err) => {
        console.log({err})
        if(err.request.status === 400){
          this.$bvToast.toast(`${err.response.data.errors[0].message}`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        }else{this.$bvToast.toast(`${err.response.data.message}`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });}
        
      })
    },
    toListWarranty() {
      this.$router.push(`/list-warranty`);
    }
  },
  computed:{
    timerId(){
      var elem = document.getElementById('some_div');
      var timerId = setInterval(countdown, 1000);
    
      function countdown() {
        if (this.timeLeft == -1) {
          clearTimeout(timerId);
          doSomething();
        } else {
          elem.innerHTML = this.timeLeft + ' seconds remaining';
          this.timeLeft--;
        }
      } 
    },
    emailForLogin(){
      const emailData = this.$store.getters['auth/getLoginOtp']
      return emailData[0].email
    },
    passwordForLogin(){
      const passwordData = this.$store.getters['auth/getLoginOtp'][0]
      return passwordData.password
    },
    emailLogin:{
      get() {
        return this.$store.getters['warranty/getEmail']
      },
      set(payload) {
        return this.$store.dispatch('warranty/setEmail',payload)
      }
    }
    // ...mapState({
    //   emailLogin: (state) => {
    //     return state.warranty.email
    //   }
    // }),
  },
  mounted(){
    console.log('this', this.$store.getters['auth/getLoginOtp'])
    if(this.$store.getters['auth/getLoginOtp'].length > 0){
        this.inputState = true
        console.log('this.inputState', this.inputState)
      }
    if(this.$store.getters['auth/getLoginOtp'].length == 0){
      this.$router.push('/pages/login')
    }

  }
}
</script>

<style scoped>
.form-line {
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
}

.form-line-otp {
  margin: 0 0 10px 0;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-validation-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-validation-input-otp {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.section-data {
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 0 0 20px 0;
}

.section-data h5 {
  position: absolute;
  top: -10px;
  left: 6px;
  background: white;
  padding: 0 10px;
}
@media only screen and (max-width: 459px) {
  .form-line label:first-child {
    margin: 0 0 5px 0;
    width: 100%;
  }
  .hide-on-mobile {display: none !important;}

  .form-line {
    flex-direction: column;
  }

  .warranty-logo {
    width: 100%;
    height: auto;
  }
}
@media only screen and (min-width: 460px) {
  .form-line label:first-child {
    margin: 0;
    width: 15%;
  }

  .form-line input {
    width: 100%;
  }

  .form-line select {
    width: 100%;
  }
  .hide-on-desktop {display: none !important;}

}


</style>